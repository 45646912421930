import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
//content
import GetIcon from '../content/GetIcon'
//component
import IntroMaintenance from '../block/IntroMaintenance'
//data
const getData = graphql`
  {
    c: allContentfulMaintenance(sort: { fields: order, order: ASC }) {
      nodes {
        title
        resume {
          resume
        }
        icon {
          title
        }
      }
    }
  }
`
const Maintenance = () => {
  const data = useStaticQuery(getData)
  return (
    <div className='maintenance'>
      <div className='flex'>
        <IntroMaintenance />
        <div className='content'>
          {data.c.nodes.map((item, index) => {
            return (
              <div key={index} className='mtcBox'>
                <GetIcon id={item.icon.title} />
                <div className='mtcContent'>
                  <h3>{item.title}</h3>
                  <p>{item.resume.resume}</p>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default Maintenance
