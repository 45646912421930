import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
//content
import RichContent from '../content/RichContent'
//scss
import '../../scss/zone/presentZone.scss'
//data
const getData = graphql`
  {
    present: allContentfulPresentation {
      nodes {
        title
        content {
          raw
        }
        img {
          title
          gatsbyImageData(
            width: 400
            height: 500
            placeholder: BLURRED
            formats: [AUTO, WEBP]
          )
        }
      }
    }
  }
`
const PresentZone = () => {
  const data = useStaticQuery(getData)

  return (
    <div className='presentZone zone'>
      <div className='container'>
        <div className='present'>
          {data.present.nodes.map((item, index) => {
            return (
              <div className='presentElm' key={index}>
                <h2 className='blockTitle'>{item.title}</h2>
                <div className='blockContent' key={index}>
                  <GatsbyImage
                    image={item.img.gatsbyImageData}
                    alt={item.img.title}
                  />
                  <RichContent myRichContent={item.content} />
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default PresentZone
