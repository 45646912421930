import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import { getImage } from 'gatsby-plugin-image'
import { convertToBgImage } from 'gbimage-bridge'
import BackgroundImage from 'gatsby-background-image'
//component
import Maintenance from '../block/Maintenance'
//scss
import '../../scss/zone/maintenanceZone.scss'
//data
const getData = graphql`
  {
    bg: contentfulConfig {
      bgMaintenance {
        gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
      }
    }
  }
`
const MaintenanceZone = () => {
  const data = useStaticQuery(getData)

  const image = getImage(data.bg.bgMaintenance)
  const bgImage = convertToBgImage(image)

  return (
    <div className='maintenanceZone zone'>
      <BackgroundImage Tag='section' {...bgImage} preserveStackingContext>
        <div className='bgOverlay'></div>
        <div className='container'>
          <Maintenance />
        </div>
      </BackgroundImage>
    </div>
  )
}

export default MaintenanceZone
