import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

//data
const getData = graphql`
  {
    c: allContentfulAsset {
      nodes {
        contentful_id
        title
        gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
      }
    }
  }
`
const GetImage = ({ contentfulID }) => {
  const data = useStaticQuery(getData)
  const url = data.c.nodes.filter((i) => i.contentful_id === contentfulID)[0]
    .gatsbyImageData
  const title = data.c.nodes.filter((i) => i.contentful_id === contentfulID)[0]
    .title

  return (
    <>
      <GatsbyImage image={url} alt={title} />
    </>
  )
}

export default GetImage
