import React, { useRef, useEffect, useState } from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
//content
import GetIcon from '../content/GetIcon'
//data
const getData = graphql`
  {
    blog: allContentfulBlog(sort: { fields: date, order: ASC }, limit: 5) {
      nodes {
        img {
          title
          gatsbyImageData(
            width: 500
            height: 300
            placeholder: BLURRED
            formats: [AUTO, WEBP]
          )
        }
        title
        resume {
          resume
        }
        slug
      }
    }
    page: contentfulPage(modele: { eq: "blog" }) {
      subtitle
    }
    d: contentfulConfig {
      blogForHome
    }
  }
`
const BlogHome = () => {
  const data = useStaticQuery(getData)
  const nbr = data.d.blogForHome
  const ref = useRef(null)
  const [largeur, setlargeur] = useState(
    ref.current ? (ref.current.offsetWidth / 2) * nbr : 0
  )

  const [etat, setEtat] = useState(0)
  const [position, setPosition] = useState(
    ref.current ? (etat * ref.current.offsetWidth) / 2 : 0
  )
  const prevClick = () => {
    if (etat === 0) {
      setEtat((etat) => nbr - 2)
    } else {
      setEtat((etat) => etat - 1)
    }
  }
  const nextClick = () => {
    if (etat === nbr - 2) {
      setEtat((etat) => 0)
    } else {
      setEtat((etat) => etat + 1)
    }
  }

  useEffect(() => {
    setlargeur((largeur) =>
      ref.current ? (ref.current.offsetWidth / 2) * nbr : 0
    )
    setPosition((position) =>
      ref.current ? (etat * ref.current.offsetWidth) / 2 : 0
    )
  }, [data.blog.nodes, etat, position, nbr])
  return (
    <div className='blog'>
      <div className='blogHeader'>
        <h2 className='blockTitle'>{data.page.subtitle}</h2>
        {nbr > 2 ? (
          <div className='blogNav'>
            <button onClick={prevClick} className='prev'>
              <GetIcon id='arrow' />
            </button>
            <button onClick={nextClick} className='next'>
              <GetIcon id='arrow' />
            </button>
          </div>
        ) : (
          ''
        )}
      </div>
      <div className='blogPost' ref={ref}>
        <div
          className='postContainer element'
          id='postContainer'
          style={{
            width: largeur,
            left: -position,
          }}
        >
          {data.blog.nodes.slice(0, nbr).map((item, index) => {
            return (
              <div className={`post`} key={index}>
                <Link to={`/blog/${item.slug}`}>
                  <GatsbyImage
                    image={item.img.gatsbyImageData}
                    alt={item.img.title}
                  />
                </Link>

                <h3>
                  <Link to={`/blog/${item.slug}`}>{item.title}</Link>
                </h3>
                <p>{item.resume.resume}</p>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default BlogHome
