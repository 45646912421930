import React, { useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'

//component
import Logo from '../block/Logo'
import MenuMain from '../menu/MenuMain'
import MenuProduct from '../menu/MenuProduct'
import Phone from '../block/Phone'
import Mail from '../block/Mail'
//scss
import '../../scss/zone/mobileZone.scss'
//data
const getData = graphql`
  {
    c: contentfulConfig {
      menuMainTitle
      menuProductTitle
    }
    d: contentfulPage(modele: { eq: "contact" }) {
      subtitle
    }
  }
`
const MobileZone = () => {
  const data = useStaticQuery(getData)

  const [mobile, setMobile] = useState(0)
  return (
    <div className='mobileZone zone'>
      <div className={`mobile ${mobile ? 'active' : ''}`}>
        <div className='container'>
          <button
            onClick={() => setMobile((mobile) => !mobile)}
            className={`mobileButton ${mobile ? 'active' : ''}`}
          >
            <div className='mobileSpan'>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </button>
          <Logo type='main' dsc={false} />
          <MenuMain title={data.c.menuMainTitle} />
          <MenuProduct title={data.c.menuProductTitle} />
          <div className='contactBlock'>
            <h2>{data.d.subtitle}</h2>
            <Phone title={true} icon={1} />
            <Mail title={true} icon={1} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default MobileZone
