import React from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'
//data

const getData = graphql`
  {
    menu: allContentfulPage(
      sort: { fields: order, order: ASC }
      filter: { menuType: { eq: "menuLegal" } }
    ) {
      nodes {
        title
        slug
      }
    }
  }
`
const MenuLegal = ({ title }) => {
  const data = useStaticQuery(getData)
  return (
    <div className='menuLegal'>
      {title ? <h2>{title}</h2> : ''}
      <ul>
        {data.menu.nodes.map((item, index) => {
          return (
            <li key={index}>
              <Link
                to={`${item.slug === '/' ? '' : '/'}${item.slug}`}
                activeClassName='is-active'
                partiallyActive={item.slug === '/' ? false : true}
              >
                {item.title}
              </Link>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default MenuLegal
