import React from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'
//content
import GetIcon from '../content/GetIcon'
//data
const getData = graphql`
  {
    logo: contentfulInfo {
      siteDsc {
        siteDsc
      }
      siteLogoFooter {
        title
      }
      siteLogoMain {
        title
      }
      siteName
    }
  }
`
const Logo = ({ dsc, type }) => {
  const data = useStaticQuery(getData)

  return (
    <div className='logo'>
      <h1>
        <Link to={`/`}>
          {type === 'main' ? (
            <GetIcon id={data.logo.siteLogoMain.title} />
          ) : type === 'footer' ? (
            <GetIcon id={data.logo.siteLogoFooter.title} />
          ) : (
            ''
          )}
        </Link>
      </h1>
      {dsc ? <p>{data.logo.siteDsc.siteDsc}</p> : ''}
    </div>
  )
}

export default Logo
