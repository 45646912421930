import React from 'react'
//component
import Logo from '../block/Logo'
import MenuMain from '../menu/MenuMain'
//scss
import '../../scss/zone/branding.scss'
const BrandingZone = () => {
  return (
    <div className='brandingZone zone'>
      <div className='container'>
        <div className='branding'>
          <Logo type='main' dsc={false} />
          <MenuMain />
        </div>
      </div>
    </div>
  )
}

export default BrandingZone
