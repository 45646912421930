import React from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'
//content
import GetIcon from '../content/GetIcon'
//data
const getData = graphql`
  {
    mail: contentfulInfo {
      mail1icon {
        title
      }
      mail1link
      mail1subtitle
      mail1title
      mail2icon {
        title
      }
    }
  }
`
const Mail = ({ title, icon, reverse }) => {
  const data = useStaticQuery(getData)
  return (
    <div className='mail'>
      {icon === 1 ? (
        <GetIcon id={data.mail.mail1icon.title} />
      ) : icon === 2 ? (
        <GetIcon id={data.mail.mail2icon.title} />
      ) : (
        ''
      )}
      <div className='content'>
        {title ? (
          reverse ? (
            <h2>{data.mail.mail1subtitle}</h2>
          ) : (
            <h2>{data.mail.mail1title}</h2>
          )
        ) : (
          ''
        )}
        <Link to={data.mail.mail1link}>
          {reverse ? data.mail.mail1title : data.mail.mail1subtitle}
        </Link>
      </div>
    </div>
  )
}

export default Mail
