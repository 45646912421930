import React from 'react'
//component
import BlogHome from '../block/BlogHome'
//scss
import '../../scss/zone/blogZone.scss'
const BlogZone = () => {
  return (
    <div className='blogZone zone'>
      <div className='container'>
        <BlogHome />
      </div>
    </div>
  )
}

export default BlogZone
