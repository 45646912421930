import React from 'react'
//components
import Breadcrumb from '../block/Breadcrumb'
//scss
import '../../scss/zone/breadcrumbZone.scss'
const BreadcrumbZone = ({ location, pageContext }) => {
  return (
    <div className='zone breadcrumbZone'>
      <div className='container'>
        <Breadcrumb location={location} pageContext={pageContext} />
      </div>
    </div>
  )
}

export default BreadcrumbZone
