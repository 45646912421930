import React from 'react'
//scss
import '../../scss/zone/titleZone.scss'
const TitleZone = ({ pageContext }) => {
  return (
    <div className='titleZone zone'>
      <div className='container'>
        <h1>{pageContext.title}</h1>
        {pageContext.subtitle ? <h2>{pageContext.subtitle}</h2> : ''}
      </div>
    </div>
  )
}

export default TitleZone
