import React, { useState, useEffect, useRef } from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
//scss
import '../../scss/zone/slideShow.scss'
//data
const getData = graphql`
  {
    c: allContentfulSlideShow(sort: { fields: order, order: ASC }) {
      nodes {
        img {
          gatsbyImageData(
            width: 700
            height: 350
            placeholder: BLURRED
            formats: [AUTO, WEBP]
          )
          title
        }
        title
        link
      }
    }
  }
`

const SlideShow = () => {
  const data = useStaticQuery(getData)
  const dataSlide = data.c.nodes

  const [counter, setCounter] = useState(0)
  const r = useRef(null)
  r.current = { counter, setCounter }

  const nbr = dataSlide.length - 1
  const timer = 7000

  useEffect(() => {
    const id = setInterval(() => {
      if (counter < nbr) {
        r.current.setCounter(r.current.counter + 1)
      } else {
        r.current.setCounter((r.current.counter = 0))
      }
    }, timer)
    return () => {
      clearInterval(id)
    }
  }, [counter, nbr])
  return (
    <>
      <div className='slideShow'>
        <div className='slideBox'>
          {dataSlide.map((item, index) => {
            return (
              <div
                key={index}
                className={
                  r.current.counter === index ? 'slide active' : 'slide'
                }
              >
                <div className='slideOverlay'></div>
                <div className='container'>
                  <div className='content'>
                    <h2>{item.title}</h2>
                    <Link to={item.link} className='button'>
                      En savoir plus
                    </Link>
                  </div>
                  <GatsbyImage
                    image={item.img.gatsbyImageData}
                    alt={item.img.title}
                  />
                </div>
              </div>
            )
          })}
        </div>
        <div className='container'>
          <div className='slideNav'>
            {Array(dataSlide.length)
              .fill(0)
              .map((item, index) => {
                return (
                  <button
                    key={index}
                    onClick={() =>
                      r.current.setCounter((r.current.counter = index))
                    }
                    className={`nav ${
                      r.current.counter === index ? 'active' : ''
                    }`}
                  >
                    {index}
                  </button>
                )
              })}
          </div>
        </div>
      </div>
    </>
  )
}

export default SlideShow
