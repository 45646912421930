import React from 'react'
//components
import TopZone from '../components/zone/TopZone'
import BrandingZone from '../components/zone/BrandingZone'
const Header = () => {
  return (
    <div className='header'>
      <TopZone />
      <BrandingZone />
    </div>
  )
}

export default Header
