import React from 'react'

//component
import IntroContact from '../block/IntroContact'
import Phone from '../block/Phone'
import Mail from '../block/Mail'

//scss
import '../../scss/zone/contactZone.scss'
const ContactZone = () => {
  return (
    <div className='contactZone zone'>
      <div className='container'>
        <div className='contact flex'>
          <IntroContact />
          <div className='main'>
            <Phone title={true} icon={1} />
            <Mail title={true} icon={1} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactZone
