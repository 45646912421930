import React from "react"
import {
  TransitionGroup,
  Transition as ReactTransition,
} from "react-transition-group"

const timeout = 1000

class Transition extends React.PureComponent {
  render() {
    const { children, location } = this.props

    return (
      <TransitionGroup className="transition">
        <ReactTransition
          key={location.pathname}
          timeout={{
            enter: timeout,
            exit: timeout,
            appear: timeout,
          }}
        >
          {status => (
            <>
              {typeof window !== `undefined` ? (
                <>
                  {(document.body.className = "")}
                  {document.body.classList.add(status)}
                </>
              ) : (
                ""
              )}

              <div className={`transition-${status}`}>{children}</div>
            </>
          )}
        </ReactTransition>
      </TransitionGroup>
    )
  }
}

export default Transition
