import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
//component
import Logo from '../components/block/Logo'
import MenuMain from '../components/menu/MenuMain'
import MenuProduct from '../components/menu/MenuProduct'
import MenuLegal from '../components/menu/MenuLegal'
//scss
import '../scss/zone/footer.scss'

//data
const getData = graphql`
  {
    c: contentfulConfig {
      menuMainTitle
      menuProductTitle
      menuLegalTitle
    }
  }
`
const Footer = () => {
  const data = useStaticQuery(getData)
  return (
    <div className='footer'>
      <div className='container flex'>
        <div className='column'>
          <Logo type='footer' dsc={true} />
        </div>

        <div className='column'>
          <MenuMain title={data.c.menuMainTitle} />
          <MenuProduct title={data.c.menuProductTitle} />
          <MenuLegal title={data.c.menuLegalTitle} />
        </div>
      </div>
    </div>
  )
}

export default Footer
