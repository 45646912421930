import React from 'react'
//components
import MobileZone from '../components/zone/MobileZone'
import Header from '../components/Header'
import SlideShow from '../components/zone/SlideShow'
import BreadcrumbZone from '../components/zone/BreadcrumbZone'
import TitleZone from '../components/zone/TitleZone'
import MaintenanceZone from '../components/zone/MaintenanceZone'
import PresentZone from '../components/zone/PresentZone'
import BlogZone from '../components/zone/BlogZone'
import ContactZone from '../components/zone/ContactZone'
import Footer from '../components/Footer'
//transition
import Transition from '../transitions/Transition'
//scss
import '../scss/reset.scss'
import '../scss/variables.scss'
import '../scss/transition.scss'
import '../scss/gird.scss'
import '../scss/global.scss'
import '../scss/mep.scss'
const Layout = ({ location, children, pageContext }) => {
  return (
    <>
      {location.pathname === '/icons/' ? (
        <div>{children}</div>
      ) : (
        <Transition location={location}>
          <div className='layout'>
            <MobileZone />
            <Header />
            {location ? (
              location.pathname === '/' ? (
                <SlideShow />
              ) : (
                <>
                  <BreadcrumbZone
                    location={location}
                    pageContext={pageContext}
                  />
                  {pageContext.type === 'list' ||
                  pageContext.type === 'listColor' ||
                  pageContext.modele === 'contact' ||
                  pageContext.type === 'defaultPage' ? (
                    <TitleZone pageContext={pageContext} />
                  ) : (
                    ''
                  )}
                </>
              )
            ) : (
              ''
            )}
            <div className='main'>{children}</div>
            <MaintenanceZone />
            {location ? location.pathname === '/' ? <PresentZone /> : '' : ''}
            {location ? location.pathname === '/' ? <BlogZone /> : '' : ''}
            <ContactZone />
            <Footer />
          </div>
        </Transition>
      )}
    </>
  )
}

export default Layout
