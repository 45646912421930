import React from "react"
import * as propTypes from "prop-types"

import { renderRichText } from "gatsby-source-contentful/rich-text"
import { BLOCKS, INLINES } from "@contentful/rich-text-types"

//content component
import GetLink from "../content/GetLink"
import GetImage from "../content/GetImage"

//richText
const options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
    [BLOCKS.EMBEDDED_ASSET]: node => (
      <GetImage contentfulID={node.data.target.sys.id} />
    ),

    [INLINES.ENTRY_HYPERLINK]: node => (
      <GetLink
        contentfulID={node.data.target.sys.id}
        value={node.content[0].value}
      />
    ),
    [INLINES.HYPERLINK]: (node, children) => (
      <a href={`${node.data.uri}`} target="_blank" rel="noreferrer">
        {children}
      </a>
    ),
  },
}
const RichContent = ({ myRichContent }) => {
  const content = myRichContent

  return (
    <div className="richContent">
      {content && renderRichText(content, options)}
    </div>
  )
}
RichContent.propTypes = {
  data: propTypes.object,
  //  data: propTypes.object.isRequired,
}

export default RichContent
