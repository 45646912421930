import React from 'react'
//component
import Phone from '../block/Phone'
import Mail from '../block/Mail'
//scss
import '../../scss/zone/topZone.scss'
const TopZone = () => {
  return (
    <div className='topZone zone'>
      <div className='container'>
        <div className='top'>
          <Phone title={false} icon={2} />
          <Mail title={false} icon={2} reverse={true} />
        </div>
      </div>
    </div>
  )
}

export default TopZone
