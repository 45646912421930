import React from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'
//data

const getData = graphql`
  {
    menu: allContentfulProductType(sort: { fields: order, order: ASC }) {
      nodes {
        colorVar {
          title
          slug
        }
        title
        slug
      }
    }
  }
`
const MenuProduct = ({ title }) => {
  const data = useStaticQuery(getData)
  return (
    <div className='menuProduct'>
      {title ? <h2>{title}</h2> : ''}
      <ul>
        {data.menu.nodes.map((item, index) => {
          return (
            <li key={index}>
              <Link
                to={`/produits/${item.slug}`}
                activeClassName='is-active'
                partiallyActive={item.slug === '/' ? false : true}
              >
                {item.title}
              </Link>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default MenuProduct
