// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-templates-template-blog-js": () => import("./../../../src/templates/TemplateBlog.js" /* webpackChunkName: "component---src-templates-template-blog-js" */),
  "component---src-templates-template-blog-post-js": () => import("./../../../src/templates/TemplateBlogPost.js" /* webpackChunkName: "component---src-templates-template-blog-post-js" */),
  "component---src-templates-template-contact-js": () => import("./../../../src/templates/TemplateContact.js" /* webpackChunkName: "component---src-templates-template-contact-js" */),
  "component---src-templates-template-page-js": () => import("./../../../src/templates/TemplatePage.js" /* webpackChunkName: "component---src-templates-template-page-js" */),
  "component---src-templates-template-product-js": () => import("./../../../src/templates/TemplateProduct.js" /* webpackChunkName: "component---src-templates-template-product-js" */),
  "component---src-templates-template-product-list-color-js": () => import("./../../../src/templates/TemplateProductListColor.js" /* webpackChunkName: "component---src-templates-template-product-list-color-js" */),
  "component---src-templates-template-product-list-js": () => import("./../../../src/templates/TemplateProductList.js" /* webpackChunkName: "component---src-templates-template-product-list-js" */),
  "component---src-templates-template-product-type-js": () => import("./../../../src/templates/TemplateProductType.js" /* webpackChunkName: "component---src-templates-template-product-type-js" */),
  "component---src-templates-template-solution-js": () => import("./../../../src/templates/TemplateSolution.js" /* webpackChunkName: "component---src-templates-template-solution-js" */)
}

