import React from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'

//data
const getData = graphql`
  {
    c: allContentfulEntry {
      nodes {
        ... on ContentfulProduits {
          title
          slug
          contentful_id
          productType {
            slug
          }
          internal {
            type
          }
        }
        ... on ContentfulBlog {
          title
          slug
          contentful_id

          internal {
            type
          }
        }
        ... on ContentfulPage {
          title
          slug
          contentful_id
          internal {
            type
          }
        }
        ... on ContentfulProductType {
          title
          slug
          contentful_id
          internal {
            type
          }
        }
      }
    }
  }
`
const GetLink = ({ contentfulID, value }) => {
  const data = useStaticQuery(getData)
  const type = data.c.nodes.filter((i) => i.contentful_id === contentfulID)[0]
    .internal.type
  return (
    <Link
      to={`${
        type === 'ContentfulProduits'
          ? `/produits/${
              data.c.nodes.filter((i) => i.contentful_id === contentfulID)[0]
                .productType.slug
            }/${
              data.c.nodes.filter((i) => i.contentful_id === contentfulID)[0]
                .slug
            }`
          : type === 'ContentfulBlog'
          ? `/blog/${
              data.c.nodes.filter((i) => i.contentful_id === contentfulID)[0]
                .slug
            }`
          : type === 'ContentfulPage'
          ? `/${
              data.c.nodes.filter((i) => i.contentful_id === contentfulID)[0]
                .slug
            }`
          : type === 'ContentfulProductType'
          ? `/produits/${
              data.c.nodes.filter((i) => i.contentful_id === contentfulID)[0]
                .slug
            }`
          : ''
      }`}
    >
      {value}
    </Link>
  )
}

export default GetLink
