import React from 'react'
import { ReactSVG } from 'react-svg'
import { graphql, useStaticQuery } from 'gatsby'
//data
const getData = graphql`
  {
    icon: allFile(filter: { sourceInstanceName: { eq: "icons" } }) {
      nodes {
        publicURL
        name
      }
    }
  }
`
const GetIcon = ({ id }) => {
  const data = useStaticQuery(getData)
  const filterData = data.icon.nodes.filter((i) => i.name === id)
  return (
    <>
      {filterData.map((item, index) => {
        return <ReactSVG src={item.publicURL} key={index} className='icon' />
      })}
    </>
  )
}

export default GetIcon
