import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

//data
const getData = graphql`
  {
    main: contentfulPage(modele: { eq: "maintenance" }) {
      slug
      subtitle
      resume {
        resume
      }
    }
    config: contentfulConfig {
      moreMaintenanceTitle
    }
  }
`
const IntroMaintenance = () => {
  const data = useStaticQuery(getData)

  return (
    <div className='intro'>
      <h2 className='blockTitle'>{data.main.subtitle}</h2>
      <p>{data.main.resume.resume}</p>
      {/*}
      <Link to={`/${data.main.slug}`} className='button reverse'>
        {data.config.moreMaintenanceTitle}
      </Link>
{*/}
    </div>
  )
}

export default IntroMaintenance
