import React from 'react'

import { graphql, useStaticQuery } from 'gatsby'

//data
const getData = graphql`
  {
    c: contentfulPage(modele: { eq: "contact" }) {
      subtitle
      resume {
        resume
      }
    }
  }
`
const IntroContact = () => {
  const data = useStaticQuery(getData)

  return (
    <div className='intro'>
      <h2 className='blockTitle'>{data.c.subtitle}</h2>
      <p>{data.c.resume.resume}</p>
    </div>
  )
}

export default IntroContact
