import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
//content
import GetIcon from '../content/GetIcon'
//data
const getData = graphql`
  {
    phone: contentfulInfo {
      phone1icon {
        title
      }
      phone1link
      phone1subtitle
      phone1title
      phone2icon {
        title
      }
    }
  }
`
const Phone = ({ title, icon, reverse }) => {
  const data = useStaticQuery(getData)
  return (
    <div className='phone'>
      {icon === 1 ? (
        <GetIcon id={data.phone.phone1icon.title} />
      ) : icon === 2 ? (
        <GetIcon id={data.phone.phone2icon.title} />
      ) : (
        ''
      )}
      <div className='content'>
        {title ? (
          reverse ? (
            <h2>{data.phone.phone1subtitle}</h2>
          ) : (
            <h2>{data.phone.phone1title}</h2>
          )
        ) : (
          ''
        )}
        <a href={data.phone.phone1link}>
          {reverse ? data.phone.phone1title : data.phone.phone1subtitle}
        </a>
      </div>
    </div>
  )
}

export default Phone
